@font-face {
  font-family: 'Avenir';
  src: url("./design/Avenir.ttc"); /* File to be stored at your site */
}
@font-face {
  font-family: 'Lato-Thin';
  src: url("./design/lato/Lato-Thin.ttf"); /* File to be stored at your site */
}
@font-face {
  font-family: 'Lato-Light';
  src: url("./design/lato/Lato-Light.ttf"); /* File to be stored at your site */
}

html {
    font-size: 100px;
}
body {
    margin: 0px;
}
#main {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    background-color: #e5efff;
}


/*------------------------------------------Move Circle-------------------------------------------*/

#bigmovecircle {
    display: none;
    position: absolute;
    left: 10px;
    bottom: 9px;
    width: 80px;
    height: 80px;
    border: solid 2px #555;
    border-radius: 100%; /* 30px */
    background-color: #eee;
    overflow: hidden;
    z-index: 100;
}
#movecircle {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 36px;
    height: 36px;
    border: solid 2px #555;
    border-radius: 15px;
    background-color: #fff;
    z-index: 102;
}
#movecircletop, #movecircleright, #movecirclebottom, #movecircleleft {
    position: absolute;
    width: 50px;
    height: 50px;
    border: solid 1px #aaa;
    z-index: 101;
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
    cursor: pointer;
}
#movecircletop {
    left: 13px;
    bottom: 48px;
}
#movecircleright {
    left: 49px;
    bottom: 12px;
}
#movecirclebottom {
    left: 13px;
    bottom: -24px;
}
#movecircleleft {
    left: -23px;
    bottom: 12px;
}
#movecircletop span, #movecircleright span, #movecirclebottom span, #movecircleleft span {
    position: absolute;
    width: 10px;
    height: 10px;
}
#movecircletop span {
    top: 26px;
    left: 15px;
    border-top: solid 1px #000;
    border-right: solid 1px #000;
}
#movecircleright span {
    top: 12px;
    left: 15px;
    border-bottom: solid 1px #000;
    border-right: solid 1px #000;
}
#movecirclebottom span {
    top: 12px;
    left: 28px;
    border-bottom: solid 1px #000;
    border-left: solid 1px #000;
}
#movecircleleft span {
    top: 27px;
    left: 28px;
    border-top: solid 1px #000;
    border-left: solid 1px #000;
}


/*----------------------------------------------Zoom----------------------------------------------*/

#zoom {
    position: absolute;
    left: 10px; /*105px;*/
    bottom: 10px;
    width: 30px;
    height: 79px;
    border: solid 2px #555;
    border-radius: 10px;
    background-color: #eee;
    z-index: 100;
}
#zoomin, #zoomout {
    width: 20px;
    height: 25px;
    float: left;
    font-size: 20px;
    cursor: pointer;
    padding: 7px 5px;
    text-align: center;
}
#zoomin {
    border-bottom: solid 1px #aaa;
}
#zoomamount {
    position: absolute;
    left: 50px; /*145px;*/
    bottom: 11px;
    width: 75px;
    height: 13px;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    background-color: #eee;
    border-radius: 6px;
    font-family: 'Avenir';
    z-index: 100;
}

/*---------------------------------------------Edit-----------------------------------------------*/

#editbutton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 16px;
    line-height: 12px;
    font-family: 'Avenir', sans-serif;
    color: #777;
    display: inline-block;
    cursor: pointer;
    z-index: 100;
}
#editbutton:hover {
    text-decoration: underline;
}

/*--------------------------------------------Popup-----------------------------------------------*/

#popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 200;
    background-color: #eee; /* #f7f7f7 */
    border: solid #bbb 1px; /* #ddd */
}
#popupX {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 29px;
    height: 29px;
    display: inline-block;
    color: #bbb; /* #ccc */
    font-family: 'Avenir', sans-serif;
    font-size: 20px;
    line-height: .15rem;
    cursor: pointer;
    z-index: 201;
    border-left: #bbb solid 1px;
    border-bottom: #bbb solid 1px;
    background-color: #eee;
    text-align: center;
}
#popupX span {
    /*position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);*/
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 7px;
}
#popupText {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: 'Avenir', sans-serif;
    font-size: 20px;
    transition: opacity .5s;
}
#editTable tbody tr input[type='text'], #editTable tbody tr input[type='password'] {
    width: 300px;
    font-size: 16px;
    border: #ccc solid 1px;
    padding: 2px;
}
.popupHeader {
    width: 100%;
    height: 30px;
    background-color: #ddd;
}
.popupHeader span {
    position: relative;
    height: 29px;
    border-bottom:#bbb solid 1px;
    /*font-size: 20px; replaced with @media*/
    text-align: center;
    display: inline-block;
    float: left;
}
.popupHeader span div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    display:inline;
    cursor:pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.popupDiv {
    width: 100%;
    height: calc(100% - 30px);
    overflow-y:auto;
}
.popupDiv table {
    width: calc(100% - 50px);
    margin: 10px auto;
    font-size: 18px;
}
@media (max-width: 550px) {
    #popupX { }
    #popupX span { }
    .popupHeader span {
        font-size: 18px;
    }
    .popupDiv table {
        font-size: 16px;
    }
    .popupDiv table td span {
        font-size: 10px;
    }
}
@media (min-width: 551px) {
    #popupX { }
    #popupX span { }
    .popupHeader span {
        font-size: 20px;
    }
    .popupDiv table {
        font-size: 18px;
    }
    .popupDiv table td span {
        font-size: 12px;
    }
}

/*--------------------------------------------Tabs------------------------------------------------*/

.oneTabs {
    width: 100%;
}
.twoTabsL {
    width: calc((100% / 2) - (30px / 2) - 1px);
    border-right:#bbb solid 1px;
}
.twoTabs {
    width: calc((100% / 2) - (30px / 2));
}

/*-------------------------------------------Message----------------------------------------------*/

#message {
    position: fixed;
    left: 50%;
    bottom: 11px;
    font-size: 14px;
    text-align: center;
    line-height: 13px;
    background-color: #eee;
    border-radius: 10px;
    font-family: 'Avenir';
    padding: 4px 10px;
    z-index: 100;
    transform: translate(-50%, 0px);
    -moz-transform: translate(-50%, 0px);
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
}

/*---------------------------------------------Info-----------------------------------------------*/

#infobutton {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
    border: #aaa solid 2px;
    border-radius: 100%;
    color: #aaa;
    font-size: 22px;
    text-align: center;
    cursor: pointer;
    z-index: 100;
}
#version {
    position: fixed;
    top: 10px;
    right: 10px;
    color: #ddd;
    font-size: 12px;
    font-family: 'Avenir', sans-serif;
    text-align: center;
    cursor: pointer;
}
#version:hover {
    text-decoration: underline;
}

/*----------------------------------------Shared Classes------------------------------------------*/

.unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}
.zeroOpacity {
    opacity: 0;
}