.line {
    position: absolute;
    border-color: #000;
    border-width: 1px;
    height: 0px;
    transition: left .4s, top .4s, width .4s, height .4s;
    width: 0px;
    z-index: 1;

    &.solid {
        border-style: solid;
    }

    &.dotted {
        border-style: dashed;
    }
}
