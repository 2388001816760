.person {
    border: solid 2px #000;
    position: absolute;
    text-align: center;
    font-size: .14rem;
    font-family: 'Avenir', sans-serif;
    cursor: pointer;
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 10;
    background-color: #fff;
    transition: left .4s, top .4s, opacity .2s;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    &.hidden {
        opacity: 0;
        z-index: 1;
    }

    &.boy {
        width: .95rem;
        height: .95rem;

        span.personName {
            /*margin: .05rem .05rem 0rem .025rem;*/
            position: absolute;
            top: .02rem;
            left: .02rem;
        }

        span.personDates {
            color: #00f;
            /*margin: 0rem .05rem .05rem .025rem;*/
            position: absolute;
            top: .53rem;
            left: .02rem;
        }
    }

    &.girl {
        border-radius: 100%;
        width: 1.05rem;
        height: 1.05rem;
        /*margin: -.05rem 0rem 0rem -.05rem;*/

        span.personName {
            /*margin: .1rem .1rem 0rem .075rem;*/
            position: absolute;
            top: .08rem;
            left: .08rem;
        }

        span.personDates {
            color: #f00;
            /*margin: 0rem .1rem .1rem .075rem;*/
            position: absolute;
            top: .58rem;
            left: .08rem;
        }
    }

    &.boy, &.girl {
        span.personName {
            width: .9rem;
            height: .51rem;
            overflow: hidden;
            display: inline-block;
            line-height: .16rem;
        }
        span.personDates {
            width: .9rem;
            height: .3rem;
            display: inline-block;
            opacity: 1;
            font-size: .11rem;
            line-height: .14rem;
        }
    }

    span p {
        margin: 0px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}